import moment from 'moment';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Button from '@material-ui/core/Button';
import React, { Component, Fragment } from 'react';

import { explorer } from '../../store/config';
import Loader from '../../components/Loader';
import { getUsersData, toggleEditUser } from '../../store/actions/Auth.js';

import './index.css';
import Slider from './slider';

class UsersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    props.getUsersData();
  };

  render() {
    let { allUsersData, isLoading } = this.props;
    const columns = [
      {
        id: 'name',
        Header: 'Name',
        accessor: allUsersData => allUsersData['name'] ? allUsersData['name'] : '-',
      }, {
        id: 'email',
        Header: 'Email',
        accessor: allUsersData => allUsersData['email'] ? allUsersData["email"] : '-',
      }, {
        id: 'phoneNumber',
        Header: 'Phone Number',
        accessor: allUsersData => allUsersData['phone'] ? allUsersData["phone"] : '-',
      }, {
        id: 'country',
        Header: 'Country',
        accessor: allUsersData => allUsersData['country'] ? allUsersData["country"] : '-',
      }, {
        id: 'createdAt',
        Header: 'Created At',
        accessor: allUsersData => allUsersData['createdAt'] ? moment(allUsersData["createdAt"]).format("lll") : '-',
      }, {
        id: 'publicAddress',
        Header: 'Public Address',
        accessor: allUsersData => allUsersData['publicAddress']
          ? <a href={`${explorer}/address/${allUsersData['publicAddress']}`} target='_blank' style={{ color: '#1c6da8' }}>
            View on Explorer
          </a>
          : '-',
      }, {
        id: 'actions',
        Header: 'Actions',
        accessor: allUsersData => <Fragment>
          <Button variant="outlined" className="mx-1 view-btn" onClick={() => this.props.toggleEditUser(allUsersData)}><i className='fa fa-edit' /></Button>
        </Fragment>,
        filterable: false,
      }
    ];

    return (
      <div className='content'>
        <div className="main-container">
          <Slider />
          {!allUsersData
            ? <Loader />
            : <Fragment>
              <div className='main-container-head mb-3'>
                <p className="main-container-heading">All Users Data</p>
              </div>
              <ReactTable
                className="table"
                data={allUsersData}
                resolveData={allUsersData => allUsersData.map(row => row)}
                columns={columns}
                minRows={20}
                filterable={true}
              />
            </Fragment>
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getUsersData,
  toggleEditUser,
};

const mapStateToProps = ({ Auth }) => {
  let { allUsersData } = Auth;
  return { allUsersData, }
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersDetails);
