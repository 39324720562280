import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import './index.css';
import { web3 } from '../../store/web3';
import Loader from '../../components/Loader';
import { Token, ICO } from '../../store/contract/index';
import { getTransactionData } from '../../store/actions/Auth.js';


class TokenDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasClosed: '',
      rate: '',
      cap: '',
      weiRaised: '',
      closingTime: '',
      capReached: '',
      goalReached: '',
      openingTime: '',
      totalSupply: '',
      name: '',
      symbol: '',
      tokensSold: '',
      totalTokens: '',
      percentage: '',

    };
    props.getTransactionData();
  };

  async componentDidMount() {

    let hasClosed = await ICO.methods.hasClosed().call();
    let rate = await ICO.methods.rate().call();
    let cap = await ICO.methods.cap().call();
    let weiRaised = await ICO.methods.weiRaised().call();
    let closingTime = await ICO.methods.closingTime().call();
    let capReached = await ICO.methods.capReached().call();
    let goalReached = await ICO.methods.goalReached().call();
    let openingTime = await ICO.methods.openingTime().call();
    let totalSupply = await Token.methods.totalSupply().call();
    let name = await Token.methods.name().call();
    let symbol = await Token.methods.symbol().call();

    let tokensSold = weiRaised / rate;
    let totalTokens = cap / rate
    let percentage = (tokensSold / totalTokens) * 100;

    this.setState({ hasClosed, rate, cap, weiRaised, closingTime, capReached, goalReached, openingTime, totalSupply, name, symbol, tokensSold, totalTokens, percentage });
  };

  componentWillReceiveProps({ adminWallets }) {
    if (adminWallets) {
      this.setState({
        charityWallet: adminWallets['charityWallet'], companyWallet: adminWallets['companyWallet'], foundersWallet: adminWallets['foundersWallet'],
        marketingWallet: adminWallets['marketingWallet']
      })
    }
  }

  render() {

    let { hasClosed, rate, cap, weiRaised, closingTime, capReached, goalReached, openingTime, totalSupply, name,
      symbol, tokensSold, totalTokens, percentage, charityWallet, companyWallet, foundersWallet, round1PublicWallet, marketingWallet } = this.state;
    return (
      <div className='content'>
        <div className="main-container pb-5">
          <div className='main-container-head mb-3'>
            <p className="main-container-heading">Token Details</p>
          </div>
          <hr />
          <div className='row'>
            {name == '' || symbol == ''
              ? <Loader />
              : <div className='edit-add pb-5 mt-3 offset-md-2 col-md-8'>
                <div className="view-data row">
                  <div className="view-data-main-title col-12">
                    <>
                      <p className="text-dark text-left ml-2 pl-5">Details of ICO Token</p>
                    </>
                  </div>
                  <div className="view-data-body col-12">
                    <div className="view-data-row my-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Token Name:</span> {name ? name : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Token Symbol:</span> {symbol ? symbol : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Rate:</span> {rate ? web3.utils.fromWei(rate.toString(), 'ether') : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Cap:</span> {cap ? web3.utils.fromWei(cap.toString(), 'ether') : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Wei Raised:</span> {weiRaised ? web3.utils.fromWei(weiRaised.toString(), 'ether') : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Token is Closed:</span> {hasClosed ? 'True' : 'False'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Cap Reached:</span> {capReached ? 'True' : 'False'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Goal Reached:</span> {goalReached ? 'True' : 'False'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Opening Time:</span> {openingTime ? moment(openingTime * 1000).format("llll") : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Closing Time:</span> {closingTime ? moment(closingTime * 1000).format("llll") : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Total Tokens:</span> {totalTokens ? totalTokens : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Percentage:</span> {percentage ? `${percentage} %` : '0'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Total Supply:</span> {totalSupply ? web3.utils.fromWei(totalSupply.toString(), 'ether') : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Total Sold:</span> {tokensSold ? tokensSold : '0'} </p>
                    </div>

                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="wallet-address text-dark text-left pl-2"><span className="view-data-title">Charity Wallet:</span> {charityWallet ? charityWallet : '-'} </p>
                    </div>

                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="wallet-address text-dark text-left pl-2"><span className="view-data-title">Company Wallet:</span> {companyWallet ? companyWallet : '-'} </p>
                    </div>

                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="wallet-address text-dark text-left pl-2"><span className="view-data-title">Founders Wallet:</span> {foundersWallet ? foundersWallet : '-'} </p>
                    </div>

                    <div className="view-data-row mt-2 ml-5 col-md-8">
                    <p className="wallet-address text-dark text-left pl-2"><span className="view-data-title">Marketing Wallet:</span> {marketingWallet ? marketingWallet : '-'} </p>
                    </div>

                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  getTransactionData
};

const mapStateToProps = ({ Auth }) => {
  let { adminWallets } = Auth;
  return { adminWallets };
};
export default connect(mapStateToProps, mapDispatchToProps)(TokenDetails);