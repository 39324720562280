import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import React, { Component, Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './index.css';
import { web3 } from '../../store/web3';
import Loader from '../../components/Loader';
import { explorer } from '../../store/config';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
    }
  };

  async componentDidMount() {
    let address = (await web3.currentProvider.enable())[0];
    this.setState({ address });
  }
  
  copied = () => EventBus.publish("success", 'Address is Copied');


  render() {
    let { address } = this.state;
    return (
      <div className='content'>
        <div className="main-container pb-5">
          <div className='main-container-head mb-3 mt-3'>
            <p className="main-container-heading">Admin Profile</p>
          </div>
          <div className="edit-add col-12">
            {address == ''
            ? <Loader />
            : <div className="edit-add-body mt-2 offset-1 col-md-10 col-sm-12 offset-1">
              <div className='row copy-address'>
                <p className="text-dark py-2"><span className="view-data-title">Address:</span> {address} </p>
                <CopyToClipboard text={address} onCopy={this.copied}>
                  <Button variant="outlined" className="view-btn ml-5 my-1">Copy</Button>
                </CopyToClipboard>
              </div>
              <div className='row copy-address'>
                <p className="text-dark py-2"><span className="view-data-title">Address on Etherscan:</span> <a className='' href={`${explorer}/address/${address}`} target="_blank"> View on Explorer</a> </p>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {

};

const mapStateToProps = ({ }) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);