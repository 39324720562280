
/*========== LOGIN ACTIONS ============= */

export const saveloginData = (data) => ({
  type: 'SAVE_LOGIN_DATA',
  payload: data,
});

export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const logout = () => ({
  type: 'LOGOUT'
});


/*========== NONCE ACTIONS ============= */

export const getNonce = ({ address, history }) => ({
  type: 'GET_NONCE',
  payload: address,
  history
});

export const setNonce = (data) => ({
  type: 'SET_NONCE',
  payload: data,
});

export const signingNonce = (data) => ({
  type: 'SIGNING_NONCE',
  payload: data,
});

/*========== DASHBOARD ACTIONS ============= */

export const getDashboardStats = () => ({
  type: 'GET_DASHBOARD_STATS',
});

export const setDashboardStats = (data) => ({
  type: 'SET_DASHBOARD_STATS',
  payload: data,
});

/*========== TRANSACTIONS DATA ACTIONS ============= */

export const getTransactionData = () => ({
  type: 'GET_TRANSACTION_DATA',
});

export const setTransactionData = (data) => ({
  type: 'SET_TRANSACTION_DATA',
  payload: data,
});

/*========== TOKEN CONTROLS ACTIONS ============= */

export const toggleExtendModal = () => ({
  type: 'TOGGLE_EXTEND_MODAL',
});

export const toggleExtendICO = () => ({
  type: 'TOGGLE_EXTEND_ICO',
});

export const refundUSDT = () => ({
  type: 'REFUND_BNB',
});


export const toggleClaimLoader = () => ({
  type: 'TOGGLE_CLAIM_LOADER',
});

export const releaseTokens = () => ({
  type: 'RELEASE_TOKENS',
});

export const toggleReleaseLoader = () => ({
  type: 'TOGGLE_RELEASE_LOADER',
});

export const setReleased = () => ({
  type: 'SET_RELEASED',
});

/*========== USERS DETAILS ACTIONS ============= */

export const getUsersData = () => ({
  type: 'GET_USERS_DATA',
});

export const setUsersData = (data) => ({
  type: 'SET_USERS_DATA',
  payload: data,
});

export const toggleEditUser = (data) => ({
  type: 'TOGGLE_EDIT_USER',
  payload: data
});

export const editUser = (data) => ({
  type: 'EDIT_USER',
  payload: data
});


/*========== MINTING ACTIONS ============= */


export const mintTokens = (data) => ({
  type: 'MINT_TOKENS',
  payload: data
});

export const mintHistory = () => ({
  type: 'MINT_HISTORY',
});

export const setMintHistory = (data) => ({
  type: 'SET_MINT_HISTORY',
  payload: data
});

export const toggleMintingModal = () => ({
  type: 'TOGGLE_MINTING_MODAL',
});

/*========== Burn ACTIONS ============= */


export const burnTokens = (data) => ({
  type: 'BURN_TOKENS',
  payload: data
});

export const burnHistory = () => ({
  type: 'BURN_HISTORY',
});

export const setBurnHistory = (data) => ({
  type: 'SET_BURN_HISTORY',
  payload: data
});

export const toggleBurnModal = () => ({
  type: 'TOGGLE_BURN_MODAL',
});

/*========== Direct Buy ACTIONS ============= */

// export const editDirectBuy = () => ({
//   type: 'EDIT_DIRECT_BUY'
// });

// export const getDirectBuy = () => ({
//   type: 'GET_DIRECT_BUY'
// });

// export const setDirectBuy = (data) => ({
//   type: 'SET_DIRECT_BUY',
//   payload: data
// });

// export const directBuy = (data) => ({
//   type: 'DIRECT_BUY',
//   payload: data
// });