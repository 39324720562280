import moment from 'moment';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import React, { Component, Fragment } from 'react';

import { explorer } from '../../store/config';
import Loader from '../../components/Loader';
import { getTransactionData } from '../../store/actions/Auth.js';

import './index.css';

class TransactionsData extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    props.getTransactionData();
  };

  render() {
    let { allTransactions, isLoading } = this.props;
    const columns = [
      {
        id: 'date',
        Header: 'Date',
        accessor: allTransactions => allTransactions['transactionTime'] ? moment(allTransactions["transactionTime"]).format("lll") : '-',
      }, {
        id: 'status',
        Header: 'Status',
        accessor: allTransactions => allTransactions['status'] ? allTransactions["status"] : '-',
      }, {
        id: 'publicAddress',
        Header: 'Public Address',
        accessor: allTransactions => allTransactions['publicAddress']
          ? <a href={`${explorer}/tx/${allTransactions['publicAddress']}`} target='_blank' style={{ color: '#1c6da8' }}>
            View on Explorer
          </a>
          : '-',
      }, {
        id: 'amountOfTokens',
        Header: 'Amount of Tokens',
        accessor: allTransactions => allTransactions['amountOfTokens'] ? allTransactions['amountOfTokens'] : '-',
      }, {
        id: 'transactionDetails',
        Header: 'Transaction Details',
        accessor: allTransactions => allTransactions['transactionHash']
          ? <a href={`${explorer}/tx/${allTransactions['transactionHash']}`} target='_blank' style={{ color: '#1c6da8' }}>
            View on Explorer
          </a>
          : '-',
      },
    ];

    return (
      <div className='content'>
        <div className="main-container">
          {!allTransactions
            ? <Loader />
            : <Fragment>
              <div className='main-container-head mb-3'>
                <p className="main-container-heading">All Transactions Data</p>
              </div>
              <ReactTable
                className="table"
                data={allTransactions}
                resolveData={allTransactions => allTransactions.map(row => row)}
                columns={columns}
                minRows={20}
                filterable={true}
              />
            </Fragment>
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getTransactionData,
};

const mapStateToProps = ({ Auth }) => {
  let { allTransactions } = Auth;
  return { allTransactions, }
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsData);
