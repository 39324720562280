const { web3 } = require('../web3');
const { env } = require('../config');

const ICOData = require(`./${env}/ICO.json`);
const TokenData = require(`./${env}/Token.json`);
const StableTokenData = require(`./${env}/StableCoin.json`);
const TokenTimelockData = require(`./${env}/TokenTimelock.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const ICOABI = ICOData['abi'];
const ICOAddress = ICOData['address'];
const ICO = new web3.eth.Contract(ICOABI, ICOAddress);

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const Token = new web3.eth.Contract(TokenABI, TokenAddress);

const TokenTimelockABI = TokenTimelockData['abi'];
const round1foundersTimeLock = TokenTimelockData['round1foundersTimeLock'];
const round2foundersTimeLock = TokenTimelockData['round2foundersTimeLock'];
const round1developerTimeLock = TokenTimelockData['round1developerTimeLock'];
const round2developerTimeLock = TokenTimelockData['round2developerTimeLock'];
const round1companyTimeLock = TokenTimelockData['round1companyTimeLock'];
const round2companyTimeLock = TokenTimelockData['round2companyTimeLock'];

// const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round1foundersTimeLock);


const StableTokenABI = StableTokenData['abi'];
const StableTokenAddress = StableTokenData['address'];
const StableToken = new web3.eth.Contract(StableTokenABI, StableTokenAddress);

module.exports = {
  ICO, ICOABI, ICOAddress,
  Token, TokenABI, TokenAddress, TokenTimelockABI,
  StableToken, StableTokenABI, StableTokenAddress,
  round1foundersTimeLock, round2foundersTimeLock, 
  round1developerTimeLock, round2developerTimeLock,
  round1companyTimeLock, round2companyTimeLock,
};
