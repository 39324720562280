import moment from 'moment';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import React, { Component, Fragment } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, DatePicker, } from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import countries from './country.json';
import NumberFormat from '../../components/NumberFormat';
import { toggleEditUser, editUser } from '../../store/actions/Auth.js';

export class Slider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            right: false,
            formData: {},
        };
    };

    componentWillReceiveProps({ singleUser }) {
        this.setState({ formData: singleUser })
    };

    /*========== EDIT USER FUNCTIONS ============= */

    handleEditChange = (e) => {
        let { formData } = this.state;
        formData[e.target.name] = e.target.value;
        this.setState({ formData });
    };

    selectedCountry = (e, value) => {
        let { formData } = this.state;
        formData['country'] = value['label'];
        this.setState({ formData });
    };

    countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    };

    changeCreatedDate = (date) => {
        let { formData } = this.state;
        formData['createdAt'] = date;
        this.setState({ formData });
    };

    submitEditUser = () => {
        let data = this.state.formData;
        this.props.editUser({ data, id: this.props.singleUser['_id'] });
    };

    /*========== EDIT USER SLIDER  ============= */

    userEdit = (singleUser) => {
        let { formData } = this.state;
        const defaultTheme = createMuiTheme({
            palette: {
                primary: {
                    main: '#1c6da8',
                    contrastText: '#fff',
                }
            },
        });

        return (
            <div className='edit-add row'>
                <div className="edit-add-title col-12">
                    <p className="text-dark">Edit User Data</p>
                </div>
                <div className="edit-add-body col-12">
                    <ValidatorForm onSubmit={this.submitEditUser}>
                        <div className="row">
                            <div className='edit-add-field col-12 mt-4'>
                                <TextValidator
                                    fullWidth
                                    type='text'
                                    name='name'
                                    id='standard-full-width'
                                    value={formData['name']}
                                    className='form-input-field'
                                    onChange={this.handleEditChange}
                                    label={<label>Name <span>*</span></label>}
                                />
                            </div>
                            <div className='edit-add-field col-12 mt-4'>
                                <TextValidator
                                    fullWidth
                                    type='email'
                                    name='email'
                                    id='standard-full-width'
                                    value={formData['email']}
                                    className='form-input-field'
                                    onChange={this.handleEditChange}
                                    label={<label>Email <span>*</span></label>}
                                />
                            </div>
                            <div className='edit-add-field col-12 mt-4'>
                                <TextValidator
                                    fullWidth
                                    type='text'
                                    name='phone'
                                    id='standard-full-width'
                                    value={formData['phone']}
                                    className='form-input-field'
                                    onChange={this.handleEditChange}
                                    label={<label>Phone Number <span>*</span></label>}
                                />
                            </div>

                            <div className='edit-add-field col-12 mt-4'>
                                <Autocomplete
                                    fullWidth
                                    id="country-select-demo"
                                    className="autocomplete-field"
                                    options={countries}
                                    defaultValue={formData['country']}
                                    getOptionLabel={(option) => formData['country'] || option.label}
                                    onChange={(event, newValue) => this.selectedCountry(event, newValue)}
                                    onClick
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span>{this.countryToFlag(option.code)}</span>
                                            &nbsp;&nbsp; {option.label} ({option.code})
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            fullWidth
                                            id='standard-full-width'
                                            value={formData['country']}
                                            className='form-input-field'
                                            label={<label>Country <span>*</span></label>}
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className='edit-add-field col-12 mt-4'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <ThemeProvider theme={defaultTheme}>
                                        <DatePicker
                                            fullWidth
                                            name="createdAt"
                                            margin="dense"
                                            format="MM/dd/yyyy"
                                            id="date-picker-dialog"
                                            className='form-input-field'
                                            value={formData['createdAt']}
                                            label={<label>Created At <span>*</span></label>}
                                            onChange={(date) => this.changeCreatedDate(date)}
                                            KeyboardButtonProps={{ 'aria-label': 'Created At' }}
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className='edit-add-buttons col-sm-12 mt-4'>
                                <Button className="cancel-btn col-4" onClick={() => this.props.toggleEditUser({})}>Cancel</Button>
                                <Button className="submit-btn col-4" type='submit' onSubmit={this.submitEditUser}>Submit</Button>
                            </div>
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        );
    };

    render() {
        let { singleUser, isEditUser } = this.props;

        return (
            <div>
                <Drawer anchor='right' open={isEditUser}>
                    <div className="slider" role="presentation">
                        {this.userEdit(singleUser)}
                    </div>
                </Drawer>
            </div>
        )
    }
}

const mapDispatchToProps = { toggleEditUser, editUser };
const mapStateToProps = ({ Auth }) => {
    let { singleUser, isEditUser } = Auth;
    return { singleUser, isEditUser };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);