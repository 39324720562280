import { connect } from 'react-redux';
import React, { Component } from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { Card, CardHeader, CardBody, CardTitle, Row, Col, } from "reactstrap";

import { web3 } from '../../store/web3';
import Loader from '../../components/Loader';
import { getDashboardStats } from '../../store/actions/Auth.js';

import "./index.css"

class Dashboard extends Component {
  constructor(props) {
    super(props);
    props.getDashboardStats();
  };

  render() {
    let { chart1, totalTransactions, noOfInvestors, totalSupply, weiRaised, icoState } = this.props.dashboardStats;
    let new_transactions = {
      data: canvas => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(34, 131, 205, 0.5)");
        gradientStroke.addColorStop(0.4, "rgba(34, 131, 205, 0.0)");
        gradientStroke.addColorStop(0, "rgba(34, 131, 205, 0.2)"); //CPT PURPLE colors

        return {
          labels: chart1['labels'],
          datasets: [{
            label: "This Month Users",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1c6da8",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1c6da8",
            pointBorderColor: "rgba(34, 131, 205, 0)",
            pointHoverBackgroundColor: "#1c6da8",
            pointBorderWidth: 10,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: chart1['data']
          }]
        };
      },
      options: chart_options
    };

    // let new_trades = {
    //   data: canvas => {
    //     let ctx = canvas.getContext("2d");

    //     let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    //     gradientStroke.addColorStop(1, "rgba(108, 55, 124,0.5)");
    //     gradientStroke.addColorStop(0.4, "rgba(108, 55, 124,0.0)");
    //     gradientStroke.addColorStop(0, "rgba(108, 55, 124,0.2)"); //CPT PURPLE colors

    //     return {
    //       labels: newTrades['labels'],
    //       datasets: [{
    //         label: "New Withdrawal",
    //         fill: true,
    //         backgroundColor: gradientStroke,
    //         borderColor: "#ffaa06",
    //         borderWidth: 2,
    //         borderDash: [],
    //         borderDashOffset: 0.0,
    //         pointBackgroundColor: "#ffaa06",
    //         pointBorderColor: "rgba(108, 55, 124,0)",
    //         pointHoverBackgroundColor: "#ffaa06",
    //         pointBorderWidth: 10,
    //         pointHoverRadius: 4,
    //         pointHoverBorderWidth: 15,
    //         pointRadius: 4,
    //         data: newTrades['data']
    //       }]
    //     };
    //   },
    //   options: chart_options
    // };

    // let pair_trades = {
    //   data: canvas => {
    //     let ctx = canvas.getContext("2d");

    //     let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    //     gradientStroke.addColorStop(1, "rgba(108, 55, 124,0.5)");
    //     gradientStroke.addColorStop(0.4, "rgba(108, 55, 124,0.0)");
    //     gradientStroke.addColorStop(0, "rgba(108, 55, 124,0.2)"); //CPT PURPLE colors

    //     return {
    //       labels: pairTrades['labels'],
    //       datasets: [{
    //         label: "New Withdrawal",
    //         fill: true,
    //         backgroundColor: gradientStroke,
    //         borderColor: "#ffaa06",
    //         borderWidth: 2,
    //         borderDash: [],
    //         borderDashOffset: 0.0,
    //         pointBackgroundColor: "#ffaa06",
    //         pointBorderColor: "rgba(108, 55, 124,0)",
    //         pointHoverBackgroundColor: "#ffaa06",
    //         pointBorderWidth: 10,
    //         pointHoverRadius: 4,
    //         pointHoverBorderWidth: 15,
    //         pointRadius: 4,
    //         data: pairTrades['data']
    //       }]
    //     };
    //   },
    //   options: chart_options
    // };

    // let new_deposit = {
    //   data: canvas => {
    //     let ctx = canvas.getContext("2d");

    //     let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    //     gradientStroke.addColorStop(1, "rgba(108, 55, 124,0.5)");
    //     gradientStroke.addColorStop(0.4, "rgba(108, 55, 124,0.0)");
    //     gradientStroke.addColorStop(0, "rgba(108, 55, 124,0.2)"); //CPT PURPLE colors

    //     return {
    //       labels: newDeposit['labels'],
    //       datasets: [{
    //         label: "New Deposit",
    //         fill: true,
    //         backgroundColor: gradientStroke,
    //         borderColor: "#ffaa06",
    //         borderWidth: 2,
    //         borderDash: [],
    //         borderDashOffset: 0.0,
    //         pointBackgroundColor: "#ffaa06",
    //         pointBorderColor: "rgba(255,255,255,0)",
    //         pointHoverBackgroundColor: "#ffaa06",
    //         pointBorderWidth: 20,
    //         pointHoverRadius: 4,
    //         pointHoverBorderWidth: 15,
    //         pointRadius: 4,
    //         data: newDeposit['data']
    //       }]
    //     };
    //   },
    //   options: chart_options
    // };

    // let coin_deposit = {
    //   data: canvas => {
    //     let ctx = canvas.getContext("2d");

    //     let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    //     gradientStroke.addColorStop(1, "rgba(108, 55, 124,0.5)");
    //     gradientStroke.addColorStop(0.4, "rgba(108, 55, 124,0.0)");
    //     gradientStroke.addColorStop(0, "rgba(108, 55, 124,0.2)"); //CPT PURPLE colors

    //     return {
    //       labels: coinDeposit['labels'],
    //       datasets: [{
    //         label: "Coin Deposit",
    //         fill: true,
    //         backgroundColor: gradientStroke,
    //         borderColor: "#ffaa06",
    //         borderWidth: 2,
    //         borderDash: [],
    //         borderDashOffset: 0.0,
    //         pointBackgroundColor: "#ffaa06",
    //         pointBorderColor: "rgba(255,255,255,0)",
    //         pointHoverBackgroundColor: "#ffaa06",
    //         pointBorderWidth: 20,
    //         pointHoverRadius: 4,
    //         pointHoverBorderWidth: 15,
    //         pointRadius: 4,
    //         data: coinDeposit['data']
    //       }]
    //     };
    //   },
    //   options: chart_options
    // };

    // let new_withdrawal = {
    //   data: canvas => {
    //     let ctx = canvas.getContext("2d");

    //     let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    //     gradientStroke.addColorStop(1, "rgba(108, 55, 124,0.5)");
    //     gradientStroke.addColorStop(0.4, "rgba(108, 55, 124,0.0)");
    //     gradientStroke.addColorStop(0, "rgba(108, 55, 124,0.2)"); //CPT PURPLE colors

    //     return {
    //       labels: newWithdrawal['labels'],
    //       datasets: [{
    //         label: "New Withdrawal",
    //         fill: true,
    //         backgroundColor: gradientStroke,
    //         borderColor: "#ffaa06",
    //         borderWidth: 2,
    //         borderDash: [],
    //         borderDashOffset: 0.0,
    //         pointBackgroundColor: "#ffaa06",
    //         pointBorderColor: "rgba(255,255,255,0)",
    //         pointHoverBackgroundColor: "#ffaa06",
    //         pointBorderWidth: 10,
    //         pointHoverRadius: 4,
    //         pointHoverBorderWidth: 15,
    //         pointRadius: 4,
    //         data: newWithdrawal['data']
    //       }]
    //     };
    //   },
    //   options: chart_options
    // };

    // let coin_withdrawal = {
    //   data: canvas => {
    //     let ctx = canvas.getContext("2d");
    //     let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    //     gradientStroke.addColorStop(1, "rgba(108, 55, 124,0.5)");
    //     gradientStroke.addColorStop(0.4, "rgba(108, 55, 124,0.0)");
    //     gradientStroke.addColorStop(0, "rgba(108, 55, 124,0.2)"); //CPT PURPLE colors

    //     return {
    //       labels: coinWithdawal['labels'],
    //       datasets: [{
    //         label: "Coin Withdrawal",
    //         fill: true,
    //         backgroundColor: gradientStroke,
    //         borderColor: "#ffaa06",
    //         borderWidth: 2,
    //         borderDash: [],
    //         borderDashOffset: 0.0,
    //         pointBackgroundColor: "#ffaa06",
    //         pointBorderColor: "rgba(255,255,255,0)",
    //         pointHoverBackgroundColor: "#ffaa06",
    //         pointBorderWidth: 10,
    //         pointHoverRadius: 4,
    //         pointHoverBorderWidth: 15,
    //         pointRadius: 4,
    //         data: coinWithdawal['data']
    //       }]
    //     };
    //   },
    //   options: chart_options
    // };

    return (
      <div className="content">
        {totalTransactions == null
          ? <Loader />
          : <div id="capture">
            <Row>
              {/* Total Investors */}
              <Col xs="3">
                <Card className="card-chart pl-3">
                  <CardHeader>
                    <CardTitle tag="h5">
                      <div tag="h5">Total Investors</div>
                      <div className='row pr-4 py-4 d-flex justify-content-end'>
                        {/* <i className="tim-icons icon-badge text-primary mx-3" /> */}
                        <h2 style={{ fontSize: '18px' }} className="card-category">{noOfInvestors}</h2>
                      </div>
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>

              {/* Current Supply */}
              <Col xs="3">
                <Card className="card-chart pl-3">
                  <CardHeader>
                    <CardTitle tag="h5">
                      <div tag="h5">Current Supply</div>
                      <div className='row pr-4 py-4 d-flex justify-content-end'>
                        {/* <i className="tim-icons icon-delivery-fast text-primary mx-3" /> */}
                        <h2 style={{ fontSize: '18px' }} className="card-category">{web3.utils.fromWei(totalSupply.toString(), 'ether')}</h2>
                      </div>
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>

              {/* Wei Raised */}
              <Col xs="3">
                <Card className="card-chart pl-3">
                  <CardHeader>
                    <CardTitle tag="h5">
                      <div tag="h5">Wei Raised</div>
                      <div className='row pr-4 py-4 d-flex justify-content-end'>
                        {/* <i className="tim-icons icon-bag-16 text-primary mx-3" /> */}
                        <h2 style={{ fontSize: '18px' }} className="card-category">{web3.utils.fromWei(weiRaised.toString(), 'ether')} USDT</h2>
                      </div>
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>

              {/* ICO Status */}
              <Col xs="3">
                <Card className="card-chart pl-3">
                  <CardHeader>
                    <CardTitle tag="h5">
                      <div tag="h5">ICO Status</div>
                      <div className='row pr-4 py-4 d-flex justify-content-end'>
                        {/* <i className="tim-icons icon-paper text-primary mx-3" /> */}
                        <h2 style={{ fontSize: '18px' }} className="card-category">{icoState}</h2>
                      </div>
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>

            {/* New Transactions Graph */}
            <Row>
              <Col xs="12">
                <Card className="card-chart py-4 px-4">
                  <CardHeader className='pb-5'>
                    <CardTitle tag="h3">
                      <div tag="h2">New Transactions</div>
                      <div className='row pt-2'>
                        <i className="tim-icons icon-notes mx-3" />
                        <h4 className="card-category">Total Transactions: {totalTransactions}</h4>
                      </div>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={new_transactions.data}
                        options={new_transactions.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <Col lg="6">
                <Card className="card-chart">
                  <CardHeader>
                    <h5 className="card-category">Trades</h5>
                    <CardTitle tag="h3">
                      <div tag="h2">Trades Stats</div>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar
                        data={new_trades.data}
                        options={new_trades.options}
                      />
                      <Doughnut data={new_trades} options={donutOptions} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card className="card-chart">
                  <CardHeader>
                    <h5 className="card-category">Pair Trades</h5>
                    <CardTitle tag="h3">
                      <div tag="h2">Pair Trades Stats</div>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={pair_trades.data}
                        options={pair_trades.options}
                      />
                      <Doughnut data={pair_trades} options={donutOptions} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
            {/* <Row>
              <Col lg="6">
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h3">
                      <div tag="h2">Withdrawal per Coin</div>
                      <div className='row'>
                        <i className="tim-icons icon-delivery-fast text-primary mx-3" />
                        <h4 className="card-category mx-2">Total Withdrawals: {totalWithdrawals}</h4>
                      </div>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar
                        data={coin_withdrawal.data}
                        options={coin_withdrawal.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h3">
                      <div tag="h2">Deposit per Coin</div>
                      <div className='row'>
                        <i className="tim-icons icon-delivery-fast text-primary mx-3" />
                        <h4 className="card-category mx-2">Total Deposits: {totalDeposits}</h4>
                      </div>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar
                        data={coin_deposit.data}
                        options={coin_deposit.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
            {/* <Row>
              <Col lg="6">
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h3">
                      <div tag="h2">Withdrawal History</div>
                      <div className='row'>
                        <i className="tim-icons icon-delivery-fast text-primary mx-3" />
                        <h4 className="card-category mx-2"></h4>
                      </div>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={new_withdrawal.data}
                        options={new_withdrawal.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h3">
                      <div tag="h2">Deposit History</div>
                      <div className='row'>
                        <i className="tim-icons icon-delivery-fast text-primary mx-3" />
                        <h4 className="card-category mx-2"></h4>
                      </div>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={new_deposit.data}
                        options={new_deposit.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { dashboardStats } = Auth
  return { dashboardStats }
};

const mapDispatchToProps = { getDashboardStats };
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);


let chart_options = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: -1,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [{
      barPercentage: 0.6,
      gridLines: {
        drawBorder: false,
        color: "rgba(29,140,248,0.0)",
        zeroLineColor: "transparent"
      },
      ticks: {
        beginAtZero: true,
        suggestedMin: 10,
        suggestedMax: 10,
        padding: 10,
        fontColor: "#9a9a9a"
      }
    }],
    xAxes: [{
      barPercentage: 0.6,
      gridLines: {
        drawBorder: false,
        color: "rgba(29,140,248,0.1)",
        zeroLineColor: "transparent"
      },
      ticks: {
        padding: 10,
        fontColor: "#9a9a9a"
      }
    }]
  }
};

let donutOptions = {
  maintainAspectRatio: false,
  legend: {
    display: true
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true
};