import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { web3 } from "../../store/web3";
import logo from '../../assets/img/logo.png';
import { isloginDisabled, getNonce } from "../../store/actions/Auth";

import './index.css';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      address: '',
    };
  };

  async componentDidMount() {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };

    let address = (await web3.currentProvider.enable())[0];
    this.setState({ address });
  };

  handleLogin = async () => {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };

    let address = (await web3.currentProvider.enable())[0];

    this.setState({ address });
    this.props.isloginDisabled();
    this.props.getNonce({ address, history: this.props.history });
  };

  render() {
    let { isLogin } = this.props;
    return (
      <div className="login-page">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 login-area">
            <div className="login-form">
              <p className="login-title">【ＬＯＧＩＮ】</p>
              <hr className='mt-3' />
              <ValidatorForm className="validator-form mt-4" onSubmit={this.handleLogin}>
                <Button type="Submit" variant="contained" className='text-white login-btn mt-4'
                  disabled={isLogin}>
                  {!isLogin
                    ? "LOGIN WITH METAMASK"
                    : <div><i className="fa fa-spinner fa-spin"></i></div>
                  }
                </Button>
              </ValidatorForm>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 login-area">
            <img className="login-page-logo" src={logo} alt='logo' />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  isloginDisabled,
  getNonce
};

const mapStateToProps = ({ Auth }) => {
  let { isLogin, } = Auth
  return { isLogin, }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);