import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm } from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider, DateTimePicker, } from '@material-ui/pickers';

import './index.css';
import { web3 } from '../../store/web3';
import Loader from '../../components/Loader';
import { explorer } from '../../store/config';
import { Token, ICO, ICOAddress, TokenTimelockABI, round1foundersTimeLock, round2foundersTimeLock, round1developerTimeLock, round2developerTimeLock } from '../../store/contract/index';
import { getTransactionData, toggleExtendModal, toggleExtendICO, refundUSDT, releaseTokens, toggleClaimLoader, toggleReleaseLoader } from '../../store/actions/Auth.js';

class TokenControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      hash: '',
      finalized: '',
      hasClosed: '',
      goalReached: '',
      isRefundUSDT: false,
      isExtend: false,
      isFinalize: false,
      isEnabled: true,

      round1foundersTimeLock: '',
      founderOneTime: '',
      isFounderOneLoader: false,
      founderOneTokensReleased: false,

      round2foundersTimeLock: '',
      founderTwoTime: '',
      isFounderTwoLoader: false,
      founderTwoTokensReleased: false,

      round1developerTimeLock: '',
      developerOneTime: '',
      isDeveloperOneLoader: false,
      developerOneTokensReleased: false,

      round2developerTimeLock: '',
      developerTwoTime: '',
      isDeveloperTwoLoader: false,
      developerTwoTokensReleased: false,

      round1CompanyTimeLock: '',
      companyOneTime: '',
      isCompanyOneRelease: false,
      companyOneTokensReleased: false,

      round2CompanyTimeLock: '',
      companyTwoTime: '',
      isCompanyTwoRelease: false,
      companyTwoTokensReleased: false,

      round1MarketingTimeLock: '',
      marketingOneTime: '',
      isMarketingOneRelease: false,
      marketingOneTokensReleased: false,

      round2MarketingTimeLock: '',
      marketingTwoTime: '',
      isMarketingTwoRelease: false,
      marketingTwoTokensReleased: false,

      liquidityTimeLock: '',
      liquidityTime: '',
      isLiquidityRelease: false,
      liquidityTokensReleased: false,

      round1CharityTimeLock: '',
      charityOneTime: '',
      isCharityOne: false,
      charity1TokensReleased: false,

      round2CharityTimeLock: '',
      charityTwoTime: '',
      isCharityTwo: false,
      charity2TokensReleased: false,

      round3CharityTimeLock: '',
      charityThreeTime: '',
      isCharityThree: false,
      charity3TokensReleased: false,


      extendDate: Date.now(),
    }
    props.getTransactionData();
  };

  async componentDidMount() {
    let finalized = await ICO.methods.finalized().call();
    let hasClosed = await ICO.methods.hasClosed().call();
    let extendDate = await ICO.methods.closingTime().call();
    let goalReached = await ICO.methods.goalReached().call();

    this.setState({ finalized, hasClosed, goalReached, extendDate: extendDate * 1000, isRefundUSDT: !goalReached && hasClosed });
  };

  async componentWillReceiveProps({ timelockWallets }) {
    let { active } = this.state;
    if (active) {

      // ROUND-1 FOUNDER TIMELOCK
      let founderOneTimelock = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round1foundersTimeLock']);
      let founderOneAddress = await founderOneTimelock.methods.beneficiary().call();
      let founderOneTimeStamp = (await founderOneTimelock.methods.releaseTime().call()) * 1000;
      let founderOneFundsUnLocked = founderOneTimeStamp < (new Date()).getTime();
      let founderOneTime = moment(founderOneTimeStamp).format('llll');
      let founderOneTokensReleased = (await Token.methods.balanceOf(timelockWallets['round1foundersTimeLock']).call()) == 0;
      this.setState({ founderOneFundsUnLocked, founderOneTime, founderOneTokensReleased, founderOneAddress })

      // ROUND-2 FOUNDER TIMELOCK
      let founderTwoTimelock = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round2foundersTimeLock']);
      let founderTwoAddress = await founderTwoTimelock.methods.beneficiary().call();
      let founderTwoTimeStamp = (await founderTwoTimelock.methods.releaseTime().call()) * 1000;
      let founderTwoFundsUnLocked = founderTwoTimeStamp < (new Date()).getTime();
      let founderTwoTime = moment(founderTwoTimeStamp).format('llll');
      let founderTwoTokensReleased = (await Token.methods.balanceOf(timelockWallets['round2foundersTimeLock']).call()) == 0;
      this.setState({ founderTwoFundsUnLocked, founderTwoTime, founderTwoTokensReleased, founderTwoAddress })

      // ROUND-1 DEVELOPER TIMELOCK
      let developerOneTimelock = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round1developerTimeLock']);
      let developerOneAddress = await developerOneTimelock.methods.beneficiary().call();
      let developerOneTimeStamp = (await developerOneTimelock.methods.releaseTime().call()) * 1000;
      let developerOneFundsUnLocked = developerOneTimeStamp < (new Date()).getTime();
      let developerOneTime = moment(developerOneTimeStamp).format('llll');
      let developerOneTokensReleased = (await Token.methods.balanceOf(timelockWallets['round1developerTimeLock']).call()) == 0;
      this.setState({ developerOneFundsUnLocked, developerOneTime, developerOneTokensReleased, developerOneAddress })

      // ROUND-2 DEVELOPER TIMELOCK
      let developerTwoTimelock = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round2developerTimeLock']);
      let developerTwoAddress = await developerTwoTimelock.methods.beneficiary().call();
      let developerTwoTimeStamp = (await developerTwoTimelock.methods.releaseTime().call()) * 1000;
      let developerTwoFundsUnLocked = developerTwoTimeStamp < (new Date()).getTime();
      let developerTwoTime = moment(developerTwoTimeStamp).format('llll');
      let developerTwoTokensReleased = (await Token.methods.balanceOf(timelockWallets['round2developerTimeLock']).call()) == 0;
      this.setState({ developerTwoFundsUnLocked, developerTwoTime, developerTwoTokensReleased, developerTwoAddress })

      // COMPANY-1 TIMELOCK
      let companyOneTimelock = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round1CompanyTimeLock']);
      let companyOneAddress = await companyOneTimelock.methods.beneficiary().call();
      let companyOneTimeStamp = (await companyOneTimelock.methods.releaseTime().call()) * 1000;
      let companyOneTime = moment(companyOneTimeStamp).format('llll');
      let companyOneTokensReleased = (await Token.methods.balanceOf(timelockWallets['round1CompanyTimeLock']).call()) == 0;
      this.setState({ companyOneTime, companyOneTimeStamp, companyOneTokensReleased, companyOneAddress });

      // COMPANY-2 TIMELOCK
      let companyTwoTimelock = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round2CompanyTimeLock']);
      let companyTwoAddress = await companyTwoTimelock.methods.beneficiary().call();
      let companyTwoTimeStamp = (await companyTwoTimelock.methods.releaseTime().call()) * 1000;
      let companyTwoTime = moment(companyTwoTimeStamp).format('llll');
      let companyTwoTokensReleased = (await Token.methods.balanceOf(timelockWallets['round2CompanyTimeLock']).call()) == 0;
      this.setState({ companyTwoTime, companyTwoTimeStamp, companyTwoTokensReleased, companyTwoAddress });

      // MARKETING-1 TIMELOCK
      let marketingOneTimelock = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round1MarketingTimeLock']);
      let marketingOneAddress = await marketingOneTimelock.methods.beneficiary().call();
      let marketingOneTimeStamp = (await marketingOneTimelock.methods.releaseTime().call()) * 1000;
      let marketingOneTime = moment(marketingOneTimeStamp).format('llll');
      let marketingOneTokensReleased = (await Token.methods.balanceOf(timelockWallets['round1MarketingTimeLock']).call()) == 0;
      this.setState({ marketingOneTime, marketingOneTimeStamp, marketingOneTokensReleased, marketingOneAddress });

      // MARKETING-2 TIMELOCK
      let marketingTwoTimelock = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round2MarketingTimeLock']);
      let marketingTwoAddress = await marketingTwoTimelock.methods.beneficiary().call();
      let marketingTwoTimeStamp = (await marketingTwoTimelock.methods.releaseTime().call()) * 1000;
      let marketingTwoTime = moment(marketingTwoTimeStamp).format('llll');
      let marketingTwoTokensReleased = (await Token.methods.balanceOf(timelockWallets['round2MarketingTimeLock']).call()) == 0;
      this.setState({ marketingTwoTime, marketingTwoTimeStamp, marketingTwoTokensReleased, marketingTwoAddress });

      // LIQUIDITY TIMELOCK
      let liquidityTimelock = new web3.eth.Contract(TokenTimelockABI, timelockWallets['liquidityTimeLock']);
      let liquidityAddress = await liquidityTimelock.methods.beneficiary().call();
      let liquidityTimeStamp = (await liquidityTimelock.methods.releaseTime().call()) * 1000;
      let liquidityTime = moment(liquidityTimeStamp).format('llll');
      let liquidityTokensReleased = (await Token.methods.balanceOf(timelockWallets['liquidityTimeLock']).call()) == 0;
      this.setState({ liquidityTime, liquidityTimeStamp, liquidityTokensReleased, liquidityAddress });

      // ROUND1 CHARITY TIMELOCK
      let charityOne = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round1CharityTimeLock']);
      let charityOneAddress = await charityOne.methods.beneficiary().call();
      let charityOneTimeStamp = (await charityOne.methods.releaseTime().call()) * 1000;
      let charityOneTime = moment(charityOneTimeStamp).format('llll');
      let charity1TokensReleased = (await Token.methods.balanceOf(timelockWallets['round1CharityTimeLock']).call()) == 0;
      this.setState({ charityOneTime, charityOneTimeStamp, charity1TokensReleased, charityOneAddress, });

      // ROUND2 CHARITY TIMELOCK
      let charityTwo = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round2CharityTimeLock']);
      let charityTwoAddress = await charityTwo.methods.beneficiary().call();
      let charityTwoTimeStamp = (await charityTwo.methods.releaseTime().call()) * 1000;
      let charityTwoTime = moment(charityTwoTimeStamp).format('llll');
      let charity2TokensReleased = (await Token.methods.balanceOf(timelockWallets['round2CharityTimeLock']).call()) == 0;
      this.setState({ charityTwoTime, charityTwoTimeStamp, charity2TokensReleased, charityTwoAddress, });

      // ROUND3 CHARITY TIMELOCK
      let charityThree = new web3.eth.Contract(TokenTimelockABI, timelockWallets['round3CharityTimeLock']);
      let charityThreeAddress = await charityThree.methods.beneficiary().call();
      let charityThreeTimeStamp = (await charityThree.methods.releaseTime().call()) * 1000;
      let charityThreeTime = moment(charityThreeTimeStamp).format('llll');
      let charity3TokensReleased = (await Token.methods.balanceOf(timelockWallets['round3CharityTimeLock']).call()) == 0;
      this.setState({ charityThreeTime, charityThreeTimeStamp, charity3TokensReleased, charityThreeAddress, });
      this.setState({ active: false });
    };

    this.setState({
      liquidityTimeLock: timelockWallets['liquidityTimeLock'],
      round1foundersTimeLock: timelockWallets['round1foundersTimeLock'], round2foundersTimeLock: timelockWallets['round2foundersTimeLock'],
      round1developerTimeLock: timelockWallets['round1developerTimeLock'], round2developerTimeLock: timelockWallets['round2developerTimeLock'],
      round1CompanyTimeLock: timelockWallets['round1CompanyTimeLock'], round2CompanyTimeLock: timelockWallets['round2CompanyTimeLock'],
      round1MarketingTimeLock: timelockWallets['round1MarketingTimeLock'], round2MarketingTimeLock: timelockWallets['round2MarketingTimeLock'],
      round1CharityTimeLock: timelockWallets['round1CharityTimeLock'], round2CharityTimeLock: timelockWallets['round2CharityTimeLock'], round3CharityTimeLock: timelockWallets['round3CharityTimeLock'],
    }, () => this.setState({ isEnabled: false }) );
  }

  finalize = async () => {
    this.setState({ isFinalize: true });

    let hasClosed = await ICO.methods.hasClosed().call();
    let finishTime = moment((await ICO.methods.closingTime().call()) * 1000).format('llll');
    if (!hasClosed) {
      this.setState({ isFinalize: false });
      EventBus.publish('error', `ICO time has not completed yet. Closing time is ${finishTime}`);
      return;
    }
    let from = (await web3.currentProvider.enable())[0];
    /** Send Transaction and Buy Token */

    web3.eth.sendTransaction({
      from,
      value: 0,
      to: ICOAddress,
      data: ICO.methods.finalize().encodeABI(),
      gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
    })
      .on('transactionHash', (hash) => {
        this.setState({ hash })
        console.log(`*******hash = `, hash)
      })
      .on('receipt', receipt => {
        this.props.toggleExtendICO();
        this.setState({ isFinalize: false, finalized: true });
        EventBus.publish('success', `ICO is successfully finalized!`);
      }).on('error', e => {
        this.setState({ isFinalize: false });
        EventBus.publish('error', `Unable to finalize ICO. View details on Explorer`);
      });
  };

  setExtendDate = async () => {
    this.props.toggleExtendModal();
    this.setState({ isExtend: true });

    let { extendDate } = this.state;
    extendDate = extendDate.getTime() / 1000;

    let finishTime = moment(extendDate * 1000).format('llll');
    let closingTime = await ICO.methods.closingTime().call();
    let timeLimit = (new Date((new Date()).setMonth((new Date()).getMonth() + 6))).getTime() / 1000;

    if (extendDate > timeLimit) {
      EventBus.publish('error', 'You cannot increase time above 6 months from now');
      let extendDate = await ICO.methods.closingTime().call();
      this.setState({ isExtend: false, extendDate: extendDate * 1000 });
      return;
    }

    if (extendDate < closingTime) {
      EventBus.publish('error', 'New time cannot be less than previous closing time');
      let extendDate = await ICO.methods.closingTime().call();
      this.setState({ isExtend: false, extendDate: extendDate * 1000 });
      return;
    }

    let from = (await web3.currentProvider.enable())[0];
    /** Send Transaction and Buy Token */
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: ICOAddress,
      data: ICO.methods.extendTime(extendDate).encodeABI(),
      gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
    })
      .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        this.setState({ isExtend: false });
        EventBus.publish('success', `ICO closing time has been extended to ${finishTime}`);
      }).on('error', e => {
        this.setState({ isExtend: false });
        EventBus.publish('error', `Unable to Extend ICO Closing Date. View details on Explorer`);
      });
  };

  distributeUsersTokens = () => {
    this.props.releaseTokens();
    this.props.toggleReleaseLoader();
  };

  founderOneTimelock = async () => {
    try {
      this.setState({ isFounderOneLoader: true });

      let { round1foundersTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round1foundersTimeLock);
      
      let releaseTimeStamp = (await TokenTimelock.methods.releaseTime().call()) * 1000;
      let releaseTime = moment(releaseTimeStamp).format('llll');
      if (releaseTimeStamp > (new Date()).getTime()) {
        this.setState({ isFounderOneLoader: false });
        EventBus.publish('info', `Release time is ${releaseTime}. You have to wait before that.`);
        return;
      }

      let from = (await web3.currentProvider.enable())[0];

      /** Release founders tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round1foundersTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let founderOneTokensReleased = (await Token.methods.balanceOf(round1foundersTimeLock).call()) == 0;
          this.setState({ isFounderOneLoader: false, founderOneTokensReleased });
          EventBus.publish('success', `Founder round-1 fund released!`);
        }).on('error', e => {
          this.setState({ isFounderOneLoader: false });
          EventBus.publish('error', `Unable to release founders round-1 fund. View details on Explorer`);
        });
    } catch (e) {
      this.setState({ isFounderOneLoader: false });
      console.log("**********error:::", e['message']);
      EventBus.publish('error', e['message']);
    }
  };

  founderTwoTimelock = async () => {
    try {
      this.setState({ isFounderTwoLoader: true });

      let { round2foundersTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round2foundersTimeLock);

      let releaseTimeStamp = (await TokenTimelock.methods.releaseTime().call()) * 1000;
      let releaseTime = moment(releaseTimeStamp).format('llll');
      if (releaseTimeStamp > (new Date()).getTime()) {
        this.setState({ isFounderTwoLoader: false });
        EventBus.publish('info', `Release time is ${releaseTime}. You have to wait before that.`);
        return;
      }

      let from = (await web3.currentProvider.enable())[0];

      /** Release founders tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round2foundersTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let founderTwoTokensReleased = (await Token.methods.balanceOf(round2foundersTimeLock).call()) == 0;
          this.setState({ isFounderTwoLoader: false, founderTwoTokensReleased });
          EventBus.publish('success', `Founder round-2 fund released!`);
        }).on('error', e => {
          this.setState({ isFounderTwoLoader: false });
          EventBus.publish('error', `Unable to release founders round-2 fund. View details on Explorer`);
        });
    } catch (e) {
      this.setState({ isFounderTwoLoader: false });
      EventBus.publish('error', e['message']);
    }
  };

  developerOneTimelock = async () => {
    try {
      this.setState({ isDeveloperOneLoader: true });

      let { round1developerTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round1developerTimeLock);

      let releaseTimeStamp = (await TokenTimelock.methods.releaseTime().call()) * 1000;
      let releaseTime = moment(releaseTimeStamp).format('llll');
      if (releaseTimeStamp > (new Date()).getTime()) {
        this.setState({ isDeveloperOneLoader: false });
        EventBus.publish('info', `Release time is ${releaseTime}. You have to wait before that.`);
        return;
      }

      let from = (await web3.currentProvider.enable())[0];

      /** Release founders tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round1developerTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let developerOneTokensReleased = (await Token.methods.balanceOf(round1developerTimeLock).call()) == 0;
          this.setState({ isDeveloperOneLoader: false, developerOneTokensReleased });
          EventBus.publish('success', `Developer round-1 fund released!`);
        }).on('error', e => {
          this.setState({ isDeveloperOneLoader: false });
          EventBus.publish('error', `Unable to release developers round-1 fund. View details on Explorer`);
        });
    } catch (e) {
      this.setState({ isDeveloperOneLoader: false });
      EventBus.publish('error', e['message']);
    }
  };

  developerTwoTimelock = async () => {
    try {
      this.setState({ isDeveloperTwoLoader: true });

      let { round2developerTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round2developerTimeLock);

      let releaseTimeStamp = (await TokenTimelock.methods.releaseTime().call()) * 1000;
      let releaseTime = moment(releaseTimeStamp).format('llll');
      if (releaseTimeStamp > (new Date()).getTime()) {
        this.setState({ isDeveloperTwoLoader: false });
        EventBus.publish('info', `Release time is ${releaseTime}. You have to wait before that.`);
        return;
      }

      let from = (await web3.currentProvider.enable())[0];

      /** Release founders tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round2developerTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let developerTwoTokensReleased = (await Token.methods.balanceOf(round2developerTimeLock).call()) == 0;
          this.setState({ isDeveloperTwoLoader: false, developerTwoTokensReleased });
          EventBus.publish('success', `Developer round-2 fund released!`);
        }).on('error', e => {
          this.setState({ isDeveloperTwoLoader: false });
          EventBus.publish('error', `Unable to release developers round-2 fund. View details on Explorer`);
        });
    } catch (e) {
      this.setState({ isDeveloperTwoLoader: false });
      EventBus.publish('error', e['message']);
    }
  };

  releaseCompanyOneTokens = async () => {
    try {
      this.setState({ isCompanyOneRelease: true });

      let { round1CompanyTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round1CompanyTimeLock);
      let from = (await web3.currentProvider.enable())[0];

      /** Release Company tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round1CompanyTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let companyOneTokensReleased = (await Token.methods.balanceOf(round1CompanyTimeLock).call()) == 0;
          this.setState({ isCompanyOneRelease: false, companyOneTokensReleased });
          EventBus.publish('success', `Round-1 Company reserved tokens released!`);
        }).on('error', e => {
          this.setState({ isCompanyOneRelease: false });
          EventBus.publish('error', `Unable to release round-1 company tokens.`);
        });
    } catch (e) {
      this.setState({ isCompanyOneRelease: false });
      EventBus.publish('error', e['message']);
    }
  };

  releaseCompanyTwoTokens = async () => {
    try {
      this.setState({ isCompanyTwoRelease: true });

      let { round2CompanyTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round2CompanyTimeLock);
      let from = (await web3.currentProvider.enable())[0];

      /** Release Company tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round2CompanyTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let companyTwoTokensReleased = (await Token.methods.balanceOf(round2CompanyTimeLock).call()) == 0;
          this.setState({ isCompanyTwoRelease: false, companyTwoTokensReleased });
          EventBus.publish('success', `Round-2 Company reserved tokens released!`);
        }).on('error', e => {
          this.setState({ isCompanyTwoRelease: false });
          EventBus.publish('error', `Unable to release round-2 company tokens.`);
        });
    } catch (e) {
      this.setState({ isCompanyTwoRelease: false });
      EventBus.publish('error', e['message']);
    }
  };

  releaseMarketingOneTokens = async () => {
    try {
      this.setState({ isMarketingOneRelease: true });

      let { round1MarketingTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round1MarketingTimeLock);
      let from = (await web3.currentProvider.enable())[0];

      /** Release Company tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round1MarketingTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let marketingOneTokensReleased = (await Token.methods.balanceOf(round1MarketingTimeLock).call()) == 0;
          this.setState({ isMarketingOneRelease: false, marketingOneTokensReleased });
          EventBus.publish('success', `Round-1 Marketing reserved tokens released!`);
        }).on('error', e => {
          this.setState({ isMarketingOneRelease: false });
          EventBus.publish('error', `Unable to release round-1 marketing tokens.`);
        });
    } catch (e) {
      this.setState({ isMarketingOneRelease: false });
      EventBus.publish('error', e['message']);
    }
  };

  releaseMarketingTwoTokens = async () => {
    try {
      this.setState({ isMarketingTwoRelease: true });

      let { round2MarketingTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round2MarketingTimeLock);
      let from = (await web3.currentProvider.enable())[0];

      /** Release Company tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round2MarketingTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let marketingTwoTokensReleased = (await Token.methods.balanceOf(round2MarketingTimeLock).call()) == 0;
          this.setState({ isMarketingTwoRelease: false, marketingTwoTokensReleased });
          EventBus.publish('success', `Round-2 Marketing reserved tokens released!`);
        }).on('error', e => {
          this.setState({ isMarketingTwoRelease: false });
          EventBus.publish('error', `Unable to release round-2 marketing tokens.`);
        });
    } catch (e) {
      this.setState({ isMarketingTwoRelease: false });
      EventBus.publish('error', e['message']);
    }
  };

  releaseLiquidityTokens = async () => {
    try {
      this.setState({ isLiquidityRelease: true });

      let { liquidityTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, liquidityTimeLock);
      let from = (await web3.currentProvider.enable())[0];

      /** Release Company tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: liquidityTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let liquidityTokensReleased = (await Token.methods.balanceOf(liquidityTimeLock).call()) == 0;
          this.setState({ isLiquidityRelease: false, liquidityTokensReleased });
          EventBus.publish('success', `Liquidity reserved tokens released!`);
        }).on('error', e => {
          this.setState({ isLiquidityRelease: false });
          EventBus.publish('error', `Unable to release liquidity tokens.`);
        });
    } catch (e) {
      this.setState({ isLiquidityRelease: false });
      EventBus.publish('error', e['message']);
    }
  };

  charityRoundOne = async () => {
    try {
      this.setState({ isCharityOne: true });

      let { round1CharityTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round1CharityTimeLock);
      let from = (await web3.currentProvider.enable())[0];

      /** Release Public Sale Round Two tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round1CharityTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let charity1TokensReleased = (await Token.methods.balanceOf(round1CharityTimeLock).call()) == 0;
          this.setState({ isCharityOne: false, charity1TokensReleased });
          EventBus.publish('success', `Charity round one tokens released!`);
        }).on('error', e => {
          this.setState({ isCharityOne: false });
          EventBus.publish('error', `Unable to release charity round one tokens.`);
        });
    } catch (e) {
      this.setState({ isCharityOne: false });
      EventBus.publish('error', e['message']);
    }
  };

  charityRoundTwo = async () => {
    try {
      this.setState({ isCharityTwo: true });

      let { round2CharityTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round2CharityTimeLock);
      let from = (await web3.currentProvider.enable())[0];

      /** Release Public Sale Round Two tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round2CharityTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let charity2TokensReleased = (await Token.methods.balanceOf(round2CharityTimeLock).call()) == 0;
          this.setState({ isCharityTwo: false, charity2TokensReleased });
          EventBus.publish('success', `Charity round two tokens released!`);
        }).on('error', e => {
          this.setState({ isCharityTwo: false });
          EventBus.publish('error', `Unable to release charity round two tokens.`);
        });
    } catch (e) {
      this.setState({ isCharityTwo: false });
      EventBus.publish('error', e['message']);
    }
  };

  charityRoundThree = async () => {
    try {
      this.setState({ isCharityThree: true });

      let { round3CharityTimeLock } = this.state;
      const TokenTimelock = new web3.eth.Contract(TokenTimelockABI, round3CharityTimeLock);
      let from = (await web3.currentProvider.enable())[0];

      /** Release Public Sale Round Two tokens from Timelock Smartcontract */
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: round3CharityTimeLock,
        data: TokenTimelock.methods.release().encodeABI(),
        gasPrice: web3.utils.toHex(web3.utils.toWei('20', 'gwei')),
      })
        .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async receipt => {
          let charity3TokensReleased = (await Token.methods.balanceOf(round3CharityTimeLock).call()) == 0;
          this.setState({ isCharityThree: false, charity3TokensReleased });
          EventBus.publish('success', `Charity round three tokens released!`);
        }).on('error', e => {
          this.setState({ isCharityThree: false });
          EventBus.publish('error', `Unable to release charity round three tokens.`);
        });
    } catch (e) {
      this.setState({ isCharityThree: false });
      EventBus.publish('error', e['message']);
    }
  };


  render() {
    const defaultTheme = createMuiTheme({
      palette: {
        primary: {
          main: '#1c6da8',
          contrastText: '#fff',
        }
      },
    });

    let { isExtendModal, isExtendICOModal, isClaim, isReleaseLoader, isReleased, pendingTransacitons, timelockWallets } = this.props;
    let { finalized, isFinalize, isRefundUSDT, hasClosed, isExtend, extendDate, goalReached, hash, isEnabled } = this.state;
    let { isCompanyOneRelease, companyOneTime, companyOneTimeStamp, companyOneTokensReleased, companyOneAddress } = this.state;
    let { isCompanyTwoRelease, companyTwoTime, companyTwoTimeStamp, companyTwoTokensReleased, companyTwoAddress } = this.state;
    let { isMarketingOneRelease, marketingOneTime, marketingOneTimeStamp, marketingOneTokensReleased, marketingOneAddress } = this.state;
    let { isMarketingTwoRelease, marketingTwoTime, marketingTwoTimeStamp, marketingTwoTokensReleased, marketingTwoAddress } = this.state;
    let { isLiquidityRelease, liquidityTime, liquidityTimeStamp, liquidityTokensReleased, liquidityAddress } = this.state;
    let { charityOneTimeStamp, charity1TokensReleased, charityOneAddress, isCharityOne, charityOneTime } = this.state;
    let { charityTwoTimeStamp, charity2TokensReleased, charityTwoAddress, isCharityTwo, charityTwoTime } = this.state;
    let { charityThreeTimeStamp, charity3TokensReleased, charityThreeAddress, isCharityThree, charityThreeTime } = this.state;
    let { isFounderOneLoader, founderOneFundsUnLocked, founderOneTime, founderOneTokensReleased, founderOneAddress, round1foundersTimeLock } = this.state;
    let { isFounderTwoLoader, founderTwoFundsUnLocked, founderTwoTime, founderTwoTokensReleased, founderTwoAddress, round2foundersTimeLock } = this.state;
    let { isDeveloperOneLoader, developerOneFundsUnLocked, developerOneTime, developerOneTokensReleased, developerOneAddress, round1developerTimeLock } = this.state;
    let { isDeveloperTwoLoader, developerTwoFundsUnLocked, developerTwoTime, developerTwoTokensReleased, developerTwoAddress, round2developerTimeLock } = this.state;

    return (
      <div className='content'>
        <div className="main-container pb-5">
          <div className='main-container-head mb-3'>
            <p className="main-container-heading">Token Controls</p>
          </div>
          <hr />
          {timelockWallets && finalized === '' || hasClosed === '' || goalReached === ''
            ? <Loader />
            : <div className='token-controls'>
              <div className='row'>

                {/* FINALIZE */}
                <div className='edit-add mt-5 offset-md-1 col-md-4 col-sm-12'>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Finalize ICO</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {isFinalize
                      ? <div className='col-12 text-center blue-loader'>
                        <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                      </div>
                      : <>
                        {!finalized
                          ? <Button className="view-btn token-control-btn col-8 mt-4" onClick={this.finalize}>Finalize</Button>
                          : <p>ICO is finalized. </p>
                        }
                      </>
                    }
                  </div>
                </div>

                {/* EXTEND DATE */}
                <div className='edit-add mt-5 offset-md-1 col-md-4 col-sm-12'>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Extend ICO Date</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {isExtend
                      ? <div className='col-12 text-center blue-loader'>
                        <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                      </div>
                      : <>
                        {!hasClosed ?
                          <Button onClick={this.props.toggleExtendModal} className="view-btn token-control-btn col-8 mt-4">Extend</Button>
                          : <p>ICO time is already reached</p>
                        }
                      </>
                    }
                  </div>
                </div>

                {/* REFUND USDT */}
                {isRefundUSDT &&
                  <div className={`edit-add mt-5 offset-md-1 col-md-4 col-sm-12`}>
                    <div className="edit-add-title col-12">
                      <p className="text-dark">Refund USDT</p>
                    </div>
                    <div className="edit-add-body col-12 mb-3">
                      {isClaim
                        ? <div className='col-12 text-center blue-loader'>
                          <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                        </div>
                        : <Button className="view-btn token-control-btn col-8 mt-4" onClick={() => { this.props.refundUSDT(); this.props.toggleClaimLoader() }}>Claim</Button>
                      }
                    </div>
                  </div>
                }

                {/* DISTRIBUTE USERS TOKENS */}
                {finalized && goalReached &&
                  <div className={`edit-add mt-5 offset-md-1 col-md-4 col-sm-12`}>
                    <div className="edit-add-title col-12">
                      <p className="text-dark">Distribute Users Tokens</p>
                    </div>
                    <div className="edit-add-body col-12 mb-3">
                      {!isReleased && pendingTransacitons
                        ? <>
                          {isReleaseLoader
                            ? <div className='col-12 text-center blue-loader'>
                              <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                            </div>
                            :
                            <>
                              <p className='pt-2 pb-3'>ICO has been completed successfully now you can realase investors tokens from Escrow.</p>
                              <Button className="view-btn token-control-btn col-8 mt-4" onClick={this.distributeUsersTokens}>Send</Button>
                            </>
                          }
                        </>
                        : <p>The tokens has been released successfully!!!</p>
                      }
                    </div>
                  </div>
                }

                {/* RELEASE ROUND-1 FOUNDER TOKENS */}
                <div className='edit-add mt-5 offset-md-1 col-md-4 col-sm-12'>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-1 Founders Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {isFounderOneLoader
                      ? <div className='col-12 text-center blue-loader'>
                        <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                      </div>
                      :
                      <>
                        {founderOneTokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${founderOneAddress}`}>Round-1 Founders address</a> from <a target='_blank' href={`${explorer}/address/${round1foundersTimeLock}`}> Timelock Smartcontract</a>.</p>
                          : founderOneFundsUnLocked ?
                            <>
                              <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.founderOneTimelock}>Release</Button>
                            </> : <p className='pt-2 pb-3'>You will be able to withdraw founders tokens after {founderOneTime} date.</p>
                        }
                      </>
                    }
                  </div>
                </div>

                {/* RELEASE ROUND-2 FOUNDER TOKENS */}
                <div className='edit-add mt-5 offset-md-1 col-md-4 col-sm-12'>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-2 Founders Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {isFounderTwoLoader
                      ? <div className='col-12 text-center blue-loader'>
                        <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                      </div>
                      :
                      <>
                        {founderTwoTokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${founderTwoAddress}`}>Round-2 Founders address</a> from <a target='_blank' href={`${explorer}/address/${round2foundersTimeLock}`}> Timelock Smartcontract</a>.</p>
                          : founderTwoFundsUnLocked ?
                            <>
                              <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.founderTwoTimelock}>Release</Button>
                            </> : <p className='pt-2 pb-3'>You will be able to withdraw founders tokens after {founderTwoTime} date.</p>
                        }
                      </>
                    }
                  </div>
                </div>

                {/* RELEASE ROUND-1 DEVELOPER TOKENS */}
                <div className='edit-add mt-5 offset-md-1 col-md-4 col-sm-12'>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-1 Developers Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {isDeveloperOneLoader
                      ? <div className='col-12 text-center blue-loader'>
                        <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                      </div>
                      :
                      <>
                        {developerOneTokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${developerOneAddress}`}>Round-1 Developer's address</a> from <a target='_blank' href={`${explorer}/address/${round1developerTimeLock}`}> Timelock Smartcontract</a>.</p>
                          : developerOneFundsUnLocked ?
                            <>
                              <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.developerOneTimelock}>Release</Button>
                            </> : <p className='pt-2 pb-3'>You will be able to withdraw developers tokens after {developerOneTime} date.</p>
                        }
                      </>
                    }
                  </div>
                </div>

                {/* RELEASE ROUND-2 DEVELOPER TOKENS */}
                <div className='edit-add mt-5 offset-md-1 col-md-4 col-sm-12'>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-2 Developers Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {isDeveloperTwoLoader
                      ? <div className='col-12 text-center blue-loader'>
                        <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                      </div>
                      :
                      <>
                        {developerTwoTokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${developerTwoAddress}`}>Round-2 Developer's address</a> from <a target='_blank' href={`${explorer}/address/${round2developerTimeLock}`}> Timelock Smartcontract</a>.</p>
                          : developerTwoFundsUnLocked ?
                            <>
                              <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.developerTwoTimelock}>Release</Button>
                            </> : <p className='pt-2 pb-3'>You will be able to withdraw developers tokens after {developerTwoTime} date.</p>
                        }
                      </>
                    }
                  </div>
                </div>

                {/* RELEASE COMPANY-1 RESERVED TOKENS */}
                <div className={`edit-add mt-5 offset-md-1 col-md-4 col-sm-12`}>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-1 Company Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {companyOneTimeStamp < Date.now()
                      ? <>
                        {companyOneTokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${companyOneAddress}`}>Round-1 Company address</a> from <a target='_blank' href={`${explorer}/address/${timelockWallets['round1CompanyTimeLock']}`}> Timelock Smartcontract</a>.</p>
                          : isCompanyOneRelease
                            ? <div className='col-12 text-center blue-loader'>
                              <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                            </div>
                            : <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.releaseCompanyOneTokens}>Release</Button>
                        }
                      </>
                      : <p>Round-1 Company reserved tokens will be released on {companyOneTime}</p>
                    }
                  </div>
                </div>

                {/* RELEASE COMPANY-2 RESERVED TOKENS */}
                <div className={`edit-add mt-5 offset-md-1 col-md-4 col-sm-12`}>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-2 Company Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {companyTwoTimeStamp < Date.now()
                      ? <>
                        {companyTwoTokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${companyTwoAddress}`}>Round-2 Company address</a> from <a target='_blank' href={`${explorer}/address/${timelockWallets['round2CompanyTimeLock']}`}> Timelock Smartcontract</a>.</p>
                          : isCompanyTwoRelease
                            ? <div className='col-12 text-center blue-loader'>
                              <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                            </div>
                            : <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.releaseCompanyTwoTokens}>Release</Button>
                        }
                      </>
                      : <p>Round-2 Company reserved tokens will be released on {companyTwoTime}</p>
                    }
                  </div>
                </div>

                {/* RELEASE MARKETING-1 RESERVED TOKENS */}
                <div className={`edit-add mt-5 offset-md-1 col-md-4 col-sm-12`}>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-1 Marketing Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {marketingOneTimeStamp < Date.now()
                      ? <>
                        {marketingOneTokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${marketingOneAddress}`}>Round-1 Marketing address</a> from <a target='_blank' href={`${explorer}/address/${timelockWallets['round1MarketingTimeLock']}`}> Timelock Smartcontract</a>.</p>
                          : isMarketingOneRelease
                            ? <div className='col-12 text-center blue-loader'>
                              <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                            </div>
                            : <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.releaseMarketingOneTokens}>Release</Button>
                        }
                      </>
                      : <p>Round-1 Marketing reserved tokens will be released on {marketingOneTime}</p>
                    }
                  </div>
                </div>

                {/* RELEASE MARKETING-2 RESERVED TOKENS */}
                <div className={`edit-add mt-5 offset-md-1 col-md-4 col-sm-12`}>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-2 Marketing Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {marketingTwoTimeStamp < Date.now()
                      ? <>
                        {marketingTwoTokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${marketingTwoAddress}`}>Round-2 Marketing address</a> from <a target='_blank' href={`${explorer}/address/${timelockWallets['round2MarketingTimeLock']}`}> Timelock Smartcontract</a>.</p>
                          : isMarketingTwoRelease
                            ? <div className='col-12 text-center blue-loader'>
                              <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                            </div>
                            : <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.releaseMarketingTwoTokens}>Release</Button>
                        }
                      </>
                      : <p>Round-2 Marketing reserved tokens will be released on {marketingTwoTime}</p>
                    }
                  </div>
                </div>

                {/* RELEASE LIQUIDITY RESERVED TOKENS */}
                <div className={`edit-add mt-5 offset-md-1 col-md-4 col-sm-12`}>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Liquidity Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {liquidityTimeStamp < Date.now()
                      ? <>
                        {liquidityTokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${liquidityAddress}`}>Liquidity address</a> from <a target='_blank' href={`${explorer}/address/${timelockWallets['liquidityTimeLock']}`}> Timelock Smartcontract</a>.</p>
                          : isLiquidityRelease
                            ? <div className='col-12 text-center blue-loader'>
                              <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                            </div>
                            : <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.releaseLiquidityTokens}>Release</Button>
                        }
                      </>
                      : <p>Liquidity reserved tokens will be released on {liquidityTime}</p>
                    }
                  </div>
                </div>

                {/* RELEASE ROUND-1 CHARITY RESERVED TOKENS */}
                <div className={`edit-add mt-5 offset-md-1 col-md-4 col-sm-12`}>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-1 Charity Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {charityOneTimeStamp < Date.now()
                      ? <>
                        {charity1TokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${charityOneAddress}`}> Round-1 Charity address</a> from <a target='_blank' href={`${explorer}/address/${timelockWallets['round1CharityTimeLock']}`}> Timelock Smartcontract</a>.</p>
                          : isCharityOne
                            ? <div className='col-12 text-center blue-loader'>
                              <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                            </div>
                            : <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.charityRoundOne}>Release</Button>
                        }
                      </>
                      : <p>Round-1 Charity tokens will be released on {charityOneTime}</p>
                    }
                  </div>
                </div>

                {/* RELEASE ROUND-2 CHARITY RESERVED TOKENS */}
                <div className={`edit-add mt-5 offset-md-1 col-md-4 col-sm-12`}>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-2 Charity Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {charityTwoTimeStamp < Date.now()
                      ? <>
                        {charity2TokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${charityTwoAddress}`}> Round-2 Charity address</a> from <a target='_blank' href={`${explorer}/address/${timelockWallets['round2CharityTimeLock']}`}> Timelock Smartcontract</a>.</p>
                          : isCharityTwo
                            ? <div className='col-12 text-center blue-loader'>
                              <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                            </div>
                            : <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.charityRoundTwo}>Release</Button>
                        }
                      </>
                      : <p>Round-2 Charity tokens will be released on {charityTwoTime}</p>
                    }
                  </div>
                </div>

                {/* RELEASE ROUND-3 CHARITY RESERVED TOKENS */}
                <div className={`edit-add mt-5 offset-md-1 col-md-4 col-sm-12`}>
                  <div className="edit-add-title col-12">
                    <p className="text-dark">Round-3 Charity Reserved Tokens</p>
                  </div>
                  <div className="edit-add-body col-12 mb-3">
                    {charityThreeTimeStamp < Date.now()
                      ? <>
                        {charity3TokensReleased ?
                          <p className='pt-2 pb-3'>Timelocked tokens has been released to <br /> <a target='_blank' href={`${explorer}/address/${charityThreeAddress}`}> Round-3 Charity address</a> from <a target='_blank' href={`${explorer}/address/${timelockWallets['round3CharityTimeLock']}`}> Timelock Smartcontract</a>.</p>
                          : isCharityThree
                            ? <div className='col-12 text-center blue-loader'>
                              <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                            </div>
                            : <Button disabled={isEnabled} className="view-btn token-control-btn col-8 mt-4" onClick={this.charityRoundThree}>Release</Button>
                        }
                      </>
                      : <p>Round-3 Charity tokens will be released on {charityThreeTime}</p>
                    }
                  </div>
                </div>

              </div>
            </div>
          }
        </div>

        {/* ---------------EXTEND DATE MODAL--------------- */}
        <Modal isOpen={isExtendModal} toggle={this.props.toggleExtendModal} className="main-modal extend-modal">
          <ModalHeader toggle={this.props.toggleExtendModal}>
            <div className="modal-logo">
              <img src={require('../../assets/img/logo.png')} alt="modal-logo" />
            </div>
            <div className="extend-modal-title text-center"><p>Extend Date</p></div>
            <hr />
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.setExtendDate}>
              <div className='row'>
                <div className="col-2"></div>
                <div className="col-8">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={defaultTheme}>
                      <DateTimePicker
                        fullWidth
                        className='text-field'
                        name="extendDate"
                        margin="normal"
                        id="date-picker-dialog"
                        label="Extend Date"
                        format="dd/MM/yy hh:mm a"
                        value={extendDate}
                        onChange={(extendDate) => this.setState({ extendDate })}
                      // KeyboardButtonProps={{ 'aria-label': 'Extend Date' }}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </div>
                <div className="col-2"></div>

                <div className="col-12 pt-4 extend-btn-div">
                  <Button onClick={this.setExtendDate} variant="contained" className="extend-btn">
                    Submit
                </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

        {/* ---------------EXTEND ICO TRANSACTION MODAL--------------- */}
        <Modal isOpen={isExtendICOModal} toggle={this.props.toggleExtendICO} className="main-modal extend-modal extend-ico-modal">
          <ModalHeader toggle={this.props.toggleExtendICO}>
            <div className="modal-logo">
              <img src={require('../../assets/img/logo.png')} alt="modal-logo" />
            </div>
            <div className="extend-modal-title text-center"><p>Extend ICO Transaction</p></div>
            <hr />
          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="row extend-transaction">
              <div className='col-4'></div>
              <div className='col-4'>
                <a className='extend-transaction-link pb-5' href={`${explorer}/tx/${hash}`} target="_blank"><p>View on Explorer</p></a>
              </div>
              <div className='col-4'></div>
            </div>
          </ModalBody>
        </Modal>

      </div>
    )
  }
}

const mapDispatchToProps = {
  toggleExtendModal, toggleExtendICO, refundUSDT, releaseTokens, toggleClaimLoader, toggleReleaseLoader, getTransactionData
};

const mapStateToProps = ({ Auth }) => {
  let { isExtendModal, isExtendICOModal, pendingTransacitons, isClaim, isReleaseLoader, isReleased, timelockWallets, adminWallets } = Auth;
  return { isExtendModal, isExtendICOModal, pendingTransacitons, isClaim, isReleaseLoader, isReleased, timelockWallets, adminWallets };
};
export default connect(mapStateToProps, mapDispatchToProps)(TokenControls);