import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Validator from 'wallet-address-validator';
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { web3 } from '../../store/web3';
import { explorer } from '../../store/config';
import { Token, TokenAddress } from '../../store/contract/index';
import { mintTokens, toggleMintingModal, mintHistory } from '../../store/actions/Auth.js';

class Minting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      address: '',
      isMinting: false,
    };

    props.mintHistory();
  };

  componentWillReceiveProps({ isMintingModal }) {
    if (!isMintingModal) this.setState({ amount: '', address: '' });
  }

  mintTokens = async () => {
    let { amount, address } = this.state;
    if (amount <= 0) {
      EventBus.publish('error', 'Amount must be greater than zero');
      return
    }

    let validAddress = Validator.validate(address, 'ETH');
    if (!validAddress) {
      EventBus.publish('error', 'Please provide valid address!');
      return
    }

    this.setState({ isMinting: true });
    let from = (await web3.currentProvider.enable())[0];
    amount = web3.utils.toWei(amount.toString(), 'ether');

    /** Send Transaction and Buy Token */
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.mint(address, amount).encodeABI(),
    })
      .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };

        this.props.mintTokens(data);
        this.setState({ isMinting: false });
      }).on('error', e => {
        this.setState({ isMinting: false });
        EventBus.publish('error', `Unable to mint Token View details on Explorer`);
      });
  }

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    let { isMintingModal, mintHistoryData } = this.props;
    let { isMinting, address, amount } = this.state;
    const columns = [
      {
        id: 'date',
        Header: 'Date',
        accessor: mintHistoryData => mintHistoryData['transactionTime'] ? moment(mintHistoryData["transactionTime"]).format("lll") : '-',
      }, {
        id: 'publicAddress',
        Header: 'Public Address',
        accessor: mintHistoryData => mintHistoryData['publicAddress']
          ? <a href={`${explorer}/address/${mintHistoryData['publicAddress']}`} target='_blank' style={{ color: '#1c6da8' }}>
            View on Explorer
          </a>
          : '-',
      }, {
        id: 'amountOfTokens',
        Header: 'Amount of Tokens',
        accessor: mintHistoryData => mintHistoryData['amountOfTokens'] ? mintHistoryData['amountOfTokens'] : '-',
      }, {
        id: 'transactionDetails',
        Header: 'Transaction Details',
        accessor: mintHistoryData => mintHistoryData['transactionHash']
          ? <a href={`${explorer}/tx/${mintHistoryData['transactionHash']}`} target='_blank' style={{ color: '#1c6da8' }}>
            View on Explorer
          </a>
          : '-',
      }
    ];

    return (
      <div className='content'>
        <div className="main-container">
          {/* {!mintHistory
            ? <Loader />
            :  */}
          <Fragment>
            <div className='main-container-head mb-3'>
              <p className="main-container-heading">Minting History</p>
              <div>
                <Button variant="outlined" className="add-btn" onClick={this.props.toggleMintingModal}>Mint Tokens</Button>
              </div>
            </div>
            <ReactTable
              className="table"
              data={mintHistoryData}
              resolveData={mintHistoryData => mintHistoryData.map(row => row)}
              columns={columns}
              minRows={20}
              filterable={true}
            />
          </Fragment>
          {/* } */}
        </div>

        {/* ---------------MINTING MODAL--------------- */}

        <Modal isOpen={isMintingModal} toggle={this.props.toggleMintingModal} className="main-modal minting-modal">
          <ModalHeader toggle={this.props.toggleMintingModal}>
            <div className="modal-logo">
              <img src={require('../../assets/img/modal-logo.png')} alt="modal-logo" />
            </div>
            <div className="minting-modal-title"><p>Mint Tokens</p></div>
            <hr />
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.mintTokens}>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='amount'
                    value={amount}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>Amount <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8 mt-4'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='address'
                    value={address}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>Address <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 minting-btn-div">

                  <Button onClick={this.mintTokens} variant="contained" className="minting-btn">
                    {isMinting
                      ?
                      <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      :
                      'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapDispatchToProps = {
  mintTokens, toggleMintingModal, mintHistory
};

const mapStateToProps = ({ Auth }) => {
  let { isMintingModal, mintHistoryData } = Auth;
  return { isMintingModal, mintHistoryData };
};

export default connect(mapStateToProps, mapDispatchToProps)(Minting);