import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Validator from 'wallet-address-validator';
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { web3 } from '../../store/web3';
import { explorer } from '../../store/config';
import { Token, TokenAddress } from '../../store/contract/index';
import { burnTokens, toggleBurnModal, burnHistory } from '../../store/actions/Auth.js';

class Burn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      isBurning: false,
    };
    props.burnHistory();
  };
  componentWillReceiveProps({ isBurnModal }) {
    if (!isBurnModal) this.setState({ amount: '' });
  }

  burnTokensFunc = async () => {
    let { amount } = this.state;
    if (amount <= 0) {
      EventBus.publish('error', 'Amount must be greater than zero');
      return;
    }

    this.setState({ isBurning: true });
    let from = (await web3.currentProvider.enable())[0];
    amount = web3.utils.toWei(amount.toString(), 'ether');

    let balance = await Token.methods.balanceOf(from).call();
    if (balance < amount) {
      balance = web3.utils.fromWei(balance.toString(), 'ether');
      EventBus.publish('error', `Burn amount is greater than your balance, which is ${balance}`);
      this.setState({ isBurning: false });
      return;
    }

    /** Send Transaction and Buy Token */
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.burn(amount).encodeABI(),
    })
      .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };
        this.props.burnTokens(data);
        this.setState({ isBurning: false });
      }).on('error', e => {
        this.setState({ isBurning: false });
        EventBus.publish('error', `Unable to Burn Token, Please View details on Explorer`);
      });
  }

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    let { isBurning, amount } = this.state;
    let { isBurnModal, burnHistoryData } = this.props;

    const columns = [
      {
        id: 'date',
        Header: 'Date',
        accessor: burnHistoryData => burnHistoryData['transactionTime'] ? moment(burnHistoryData["transactionTime"]).format("lll") : '-',
      }, {
        id: 'publicAddress',
        Header: 'Public Address',
        accessor: burnHistoryData => burnHistoryData['publicAddress']
          ? <a href={`${explorer}/address/${burnHistoryData['publicAddress']}`} target='_blank' style={{ color: '#1c6da8' }}>
            View on Explorer
          </a>
          : '-',
      }, {
        id: 'amountOfTokens',
        Header: 'Amount of Tokens',
        accessor: burnHistoryData => burnHistoryData['amountOfTokens'] ? burnHistoryData['amountOfTokens'] : '-',
      }, {
        id: 'transactionDetails',
        Header: 'Transaction Details',
        accessor: burnHistoryData => burnHistoryData['transactionHash']
          ? <a href={`${explorer}/tx/${burnHistoryData['transactionHash']}`} target='_blank' style={{ color: '#1c6da8' }}>
            View on Explorer
          </a>
          : '-',
      }
    ];

    return (
      <div className='content'>
        <div className="main-container">
          {/* {!burnHistoryData
            ? <Loader />
            :  */}
          <Fragment>
            <div className='main-container-head mb-3'>
              <p className="main-container-heading">UWT Burn History</p>
              <div>
                <Button variant="outlined" className="add-btn" onClick={this.props.toggleBurnModal}>Burn Tokens</Button>
              </div>
            </div>
            <ReactTable
              className="table"
              data={burnHistoryData}
              resolveData={burnHistoryData => burnHistoryData.map(row => row)}
              columns={columns}
              minRows={20}
              filterable={true}
            />
          </Fragment>
          {/* } */}
        </div>

        {/* ---------------BURN MODAL--------------- */}

        <Modal isOpen={isBurnModal} toggle={this.props.toggleBurnModal} className="main-modal burn-modal">
          <ModalHeader toggle={this.props.toggleBurnModal}>
            <div className="modal-logo">
              <img src={require('../../assets/img/logo.png')} alt="modal-logo" />
            </div>
            <div className="burn-modal-title"><p>Burn UWT Tokens</p></div>
            <div className="burn-modal-line"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.burnTokensFunc}>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='amount'
                    value={amount}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>No of UWT tokens <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 burn-btn-div">
                  <Button type="submit" variant="contained" className="burn-btn">
                    {isBurning
                      ? <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      : 'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleBurnModal, burnHistory, burnTokens
};

const mapStateToProps = ({ Auth }) => {
  let { isBurnModal, burnHistoryData } = Auth;
  return { isBurnModal, burnHistoryData };
};
export default connect(mapStateToProps, mapDispatchToProps)(Burn);