import { PURGE } from "redux-persist";
import { setToken } from '../axios';

var initialState =
{
  nonce: '',
  isLogin: false,
  auth: localStorage.getItem('token'),

  allTransactions: [],
  pendingTransacitons: [],
  userDetails: {},
  adminWallets: {},
  timelockWallets: {},

  allUsersData: [],
  singleUser: {},
  isEditUser: false,

  isClaim: false,
  isExtendModal: false,
  isExtendICOModal: false,
  isReleaseLoader: false,
  isReleased: false,

  tokenDetails: {},

  mintHistoryData: [],
  isMintingModal: false,

  burnHistoryData: [],
  isBurnModal: false,

  isDirectBuy: true,
  directBuyInfo: {},

  dashboardStats: {
    noOfInvestors: '-',
    totalSupply: '-',
    weiRaised: '-',
    icoState: '-',
    totalTransactions: '',
    chart1: {
      labels: ["15 - August", "28 - August", "30 - August",],
      data: [2, 2, 4,],
    },
  },

};

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE: return initialState;

    case 'SAVE_LOGIN_DATA':
      setToken(payload);
      localStorage.setItem('token', payload);
      return {
        ...state,
        auth: payload,
      };

    case 'IS_LOGIN_DISABLED':
      return {
        ...state,
        isLogin: !state.isLogin,
      };

    case 'LOGOUT':
      setToken();
      localStorage.removeItem('token');
      return {
        ...state,
        auth: ''
      };

    /*========== NONCE REDUCERS ============= */

    case 'SET_NONCE':
      return {
        ...state,
        nonce: payload
      };

    /*========== DASHBOARD REDUCERS ============= */

    case 'SET_DASHBOARD_STATS':
      return {
        ...state,
        dashboardStats: payload['chartData'],
      };

    /*========== TRANSACTIONS DATA REDUCERS ============= */

    case 'SET_TRANSACTION_DATA':
      return {
        ...state,
        allTransactions: payload['allTransactionData'],
        pendingTransacitons: payload['pendingTransacitons'],
        userDetails: payload['user'],
        adminWallets: payload['user']['adminWallets'],
        timelockWallets: payload['user']['timelockWallets'],
      };

    /*========== TOKEN CONTROLS REDUCERS ============= */

    case 'TOGGLE_EXTEND_MODAL':
      return {
        ...state,
        isExtendModal: !state.isExtendModal,
      };

    case 'TOGGLE_EXTEND_ICO':
      return {
        ...state,
        isExtendICOModal: !state.isExtendICOModal,
      };


    case 'TOGGLE_CLAIM_LOADER':
      return {
        ...state,
        isClaim: !state.isClaim,
      };

    case 'TOGGLE_RELEASE_LOADER':
      return {
        ...state,
        isReleaseLoader: !state.isReleaseLoader,
      };

    case 'SET_RELEASED':
      return {
        ...state,
        isReleased: !state.isReleased,
      };

    /*========== USERS DETAILS REDUCERS ============= */

    case 'SET_USERS_DATA':
      return {
        ...state,
        allUsersData: payload,
      };

    case 'TOGGLE_EDIT_USER':
      return {
        ...state,
        singleUser: payload,
        isEditUser: !state.isEditUser,
      };

    /*========== MINTING REDUCERS ============= */

    case 'TOGGLE_MINTING_MODAL':
      return {
        ...state,
        isMintingModal: !state.isMintingModal,
      };

    case 'SET_MINT_HISTORY':
      return {
        ...state,
        mintHistoryData: payload
      }

    /*========== BURN REDUCERS ============= */

    case 'TOGGLE_BURN_MODAL':
      return {
        ...state,
        isBurnModal: !state.isBurnModal,
      };

    case 'SET_BURN_HISTORY':
      return {
        ...state,
        burnHistoryData: payload
      };

    /*========== DIRECT BUY REDUCERS ============= */

    // case 'EDIT_DIRECT_BUY':
    //   return {
    //     ...state,
    //     isDirectBuy: !state.isDirectBuy,
    //   };

    // case 'SET_DIRECT_BUY':
    //   return {
    //     ...state,
    //     directBuyInfo: payload,
    //   };

    default:
      return state;
  }
};

export default Auth;